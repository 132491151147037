<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'" :fill="color">
    <g xmlns="http://www.w3.org/2000/svg" id="Layer_8">
        <g>
            <circle class="st0" cx="125" cy="125" r="124.5"/>
            <g>
                <g>
                    <path class="st1" d="M152.8,152.4c3,2,4.7,4.3,4.7,6.7c0,7.2-14.5,13.1-32.4,13.1s-32.4-5.8-32.4-13.1c0-2.5,1.7-4.8,4.7-6.7      c-8.9,3.3-14.5,8.1-14.5,13.5c0,9.9,18.9,17.9,42.3,17.9s42.3-8,42.3-17.9C167.3,160.5,161.6,155.7,152.8,152.4z"/>
                    <g>
                        <g>
                            <g>
                                <path id="XMLID_11_" class="st2" d="M125,163.7c-2.9-4.5-5.8-8.8-8.6-13.1c-5.9-9-11.8-18.1-17.7-27.2         c-5.1-7.9-8-16.4-7.5-25.9c1-15.7,13.9-29.4,29.6-31c18.4-2,34.3,9.8,37.6,28c1.4,7.7,0,15.2-3.4,22.2         c-4.8,10-11.7,18.7-17.5,28c-3.8,6-7.8,11.9-11.7,17.9C125.6,162.8,125.3,163.1,125,163.7z M143.9,100.1         c0-10.4-8.3-19-18.8-19.1S106,89.4,106,99.8c0,10.8,8.4,18.7,18.1,19.1C136,119.4,143.9,109.9,143.9,100.1z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
    name: 'iconAddresses',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    .st0{fill:#FEF7EA;}
	.st1{fill:#F9CC95;}
	.st2{fill:#F5B14D;}
</style>